<template>
  <v-container fluid>
    <v-card>
      <v-card-title :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-header">


        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ this.$t($t('erp.mhdList')) }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <v-btn :disabled="this.getLoading" :loading="this.getLoading" @click="reload"
                 class="btn-icon btn-icon-only elevation-0" color="primary" fab small>
            <v-icon color="white">filter_list</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row class="pt-5">
          <v-col class="pt-0 pb-0" cols="12" md="4" sm="6">
            <BaseDateInput :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" dense :label="$t('accounting.lang_dailyReportStart')"
                          outlined type="date" v-model="form.start"/>
          </v-col>

          <v-col class="pt-0 pb-0" cols="12" md="4" sm="6">
            <BaseDateInput :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard" dense :label="$t('accounting.lang_dailyReportEnd')"
                          outlined type="date" v-model="form.end"/>
          </v-col>

          <v-col class="pt-0 pb-0" cols="12" md="4" sm="12">
            <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="storages" :loading="this.loading"
                      @focus="showTouchKeyboard" dense item-text="name" item-value="id" :label="$t('erp.lang_chooseWarehouse')"
                      multiple outlined
                      v-model="form.storage_id"/>
          </v-col>

          <v-col class="pa-0 ma-0" cols="12">

            <Datatable
                :api-endpoint="ENDPOINTS.DATATABLES.ERP.MHDLIST"
                :datatable-headers="datatableHeaders"
                :elevation="0"
                :excel-columns="excelColumns"
                excel-file-name="Mhd List"
                ref="mhdList"
                v-bind:data="this.params"
            />
          </v-col>
          <v-col cols="12">
            <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
              <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                  :defaultKeySet="touchKeyboard.keySet"
                                  :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                  :options="touchKeyboard.options" class="internalWidthExpanded"
                                  id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";
import Datatable from "@/components/datatable/Datatable";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  name: "MhdItemsComponent",
  components: {Datatable, BaseDateInput},
  mixins: [mixin],
  data: () => {
    return {
      getLoading: false,
      ENDPOINTS,
      loading: false,
      storages: [],
      form: {
        start: "",
        end: "",
        storage_id: 0
      }
    }
  },
  methods: {
    reload() {
      this.getLoading = true;
      this.$refs.mhdList.getDataFromApi();
      this.$refs.mhdList.resetSelectedRows();
      this.getLoading = false;
    },
    loadStorages() {
      this.loading = true;
      this.axios.post(ENDPOINTS.ERP.STORAGES.GET).then((res) => {
        this.storages = res.data.storageInformation.map((elt) => {
          return {
            id: elt[0],
            name: elt[1]
          }
        });

        this.storages.unshift({
          id: 0,
          name: "All"
        })
      }).catch((err) => {

      }).finally(() => {
        this.loading = false;
      })
    },
    formatDate(date = new Date()) {
      if (date instanceof Date) {
        let tmp = date.toLocaleDateString().split('/');
        return tmp[2] + "-" + tmp[1] + "-" + tmp[0];
      }
      return "";
    }
  },
  mounted() {
    this.loadStorages();
    //default date
    /*this.form.start = this.formatDate();
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    this.form.end = this.formatDate(currentDate)*/
  },
  watch: {
    'form.start': function (val) {
      if (new Date(val).getTime() > new Date(this.form.end).getTime()) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('reservation.start_date_bigger_end')
        });
      }
    }
  },
  computed: {
    excelColumns: function () {
      return [
        {
          label: 'ID',
          field: 'id',
        },
        {label: this.$t('erp.lang_ware_create_id'), field: 'ean'},
        {label: this.$t('erp.lang_posItemName'), field: 'name'},
        {label: this.$t('erp.lang_QTY'), field: 'QTY'},
        {label: 'MHD', field: 'mhd'},
        {label: this.$t('erp.lang_chargeNo'), field: 'chargeNo'},
        {label: this.$t('erp.lang_stockinventory'), field: 'stock'},
      ]
    },
    datatableHeaders: function () {
      return [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {text: this.$t('erp.lang_ware_create_id'), value: 'ean'},
        {text: this.$t('erp.lang_posItemName'), value: 'name'},
        {text: this.$t('erp.lang_QTY'), value: 'QTY'},
        {text: 'MHD', value: 'mhd'},
        {text: this.$t('erp.lang_chargeNo'), value: 'chargeNo'},
        {text: this.$t('erp.lang_stockinventory'), value: 'stock',hide: true},
      ]
    },
    params: {
      get() {
        return {
          start: (this.form.start !== undefined && this.form.start !== null && this.form.start !== "") ? new Date(this.form.start).getTime() / 1000 : "",
          end: (this.form.end !== undefined && this.form.end !== null && this.form.end !== "") ? new Date(this.form.end).getTime() / 1000 : "",
          storage_id: this.form.storage_id
        }
      }
    },
    ...mapState([
      'app'
    ]),
  }

}
</script>

<style scoped>

</style>
<template>
  <v-container class="pa-0" fluid>
    <page-title
        :heading="$t('erp.mhdList') "
        :icon="icon"
        :permissionAdd="true"
        :subheading="$t('erp.mhdList')"
    ></page-title>
    <div class="app-main__inner">
      <mhd-items-component/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import MhdItemsComponent from "@/components/erp/baseData/mhditems/MhdItemsComponent";

export default {
  name: "index",
  components: {
    MhdItemsComponent,
    PageTitle,

  },
  data: () => ({
    icon: "pe-7s-date icon-gradient bg-tempting-azure"
  })

}
</script>

<style scoped>

</style>